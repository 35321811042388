import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../environments/environment';
import { Flows } from '../interfaces/flows';
import { CookieService } from 'ngx-cookie-service';
environment;
@Injectable({
  providedIn: 'root',
})
export class FlowsService {
  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService
  ) { }
  sendFlow(flows: Flows[], name: string, category: string): Observable<any> {
    let header = new HttpHeaders();
    header = header.append(
      'Authorization',
      `Bearer ${this.cookieService.get('token')
        ? this.cookieService.get('token')
        : sessionStorage.getItem('token')
      }`
    );
    let body = {
      name: name,
      category: category,
      steps: flows,
    };
    return this.httpClient.post(`${environment.API_URL}api/flows`, body, {
      headers: header,
    });
  }
  getFlow(): Observable<any> {
    let header = new HttpHeaders();
    header = header.append(
      'Authorization',
      `Bearer ${this.cookieService.get('token')
        ? this.cookieService.get('token')
        : sessionStorage.getItem('token')
      }`
    );
    return this.httpClient.get(`${environment.API_URL}api/categories`, {
      headers: header,
    });
  }
  deleteFlow(id: number): Observable<any> {
    let header = new HttpHeaders();
    header = header.append(
      'Authorization',
      `Bearer ${this.cookieService.get('token')
        ? this.cookieService.get('token')
        : sessionStorage.getItem('token')
      }`
    );
    return this.httpClient.delete(`${environment.API_URL}api/flows/${id}`, {
      headers: header,
      observe: 'response',
    });
  }

  getOneFlow(flowId: number): Observable<any> {
    let header = new HttpHeaders();
    header = header.append(
      'Authorization',
      `Bearer ${this.cookieService.get('token')
        ? this.cookieService.get('token')
        : sessionStorage.getItem('token')
      }`
    );
    return this.httpClient.get(`${environment.API_URL}api/flows/${flowId}`, {
      headers: header,
      observe: 'response',
    });
  }

  Deletecategory(categoryID: number): Observable<any> {
    let header = new HttpHeaders();
    header = header.append(
      'Authorization',
      `Bearer ${this.cookieService.get('token')
        ? this.cookieService.get('token')
        : sessionStorage.getItem('token')
      }`
    );
    return this.httpClient.delete(`${environment.API_URL}api/categories/${categoryID}`, {
      headers: header,
      observe: 'response',
    });
  }
}
